<template>
  <div>
    <span
      :class="`text-${variant} border-${variant}`"
      class="border rounded bg-white status-badge"
    >
      <span class="font-weight-bold"><slot /></span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    variant: { type: String, required: true, default: () => 'secondary' },
  },
}
</script>

<style lang="scss" scoped>
  .status-badge {
    padding: 2px 12px;
    font-size: 12px;
  }
</style>
