<template>
  <b-card>
    <b-spinner
      v-if="initialLoader"
      small
      label="Small Spinner"
    />

    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
      class="mb-0"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>
    <div v-if="!initialLoader && !errorMessage">
      <div

        class="d-flex justify-content-between align-items-center"
      >
        <span v-b-toggle.attention-required-list>
          <b-badge
            pill
            :variant="attentionRequiredBills.length > 0 ? 'danger' : 'primary'"
          >
            {{ attentionRequiredBills.length }}
          </b-badge>
          Attention Required Bills
        </span>
        <b-button
          :disabled="refreshLoader"
          variant="text"
          size="sm"
          class="p-0"
          @click.prevent="getData({isRefresh: true})"
        >
          <b-spinner
            v-if="refreshLoader"
            small
            label="Small Spinner"
          />
          <feather-icon
            v-else
            icon="RotateCcwIcon"
            size="16"
          />
        </b-button>
      </div>
      <b-collapse
        id="attention-required-list"
        class="mt-2"
      >
        <div class="attention-required-list">
          {{ attentionRequiredBills.join(", ") }}
        </div>
      </b-collapse>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BAlert, BCollapse, VBToggle, BBadge, BButton,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BSpinner,
    BAlert,
    BCollapse,
    BBadge,
    BButton,
  },
  props: {
    needRefresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialLoader: false,
      errorMessage: null,
      attentionRequiredBills: [],
      refreshLoader: false,
    }
  },
  watch: {
    needRefresh(newVal) {
      if (newVal) {
        this.getData({ isRefresh: true })
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData(options = {}) {
      const { isRefresh = false } = options
      if (isRefresh) {
        this.refreshLoader = true
      } else {
        this.initialLoader = true
      }
      axios
        .get('operations/bill_rack_group_status/attention_required')
        .then(res => {
          this.attentionRequiredBills = res.data
          this.errorMessage = null
          this.initialLoader = false
          this.refreshLoader = false
        })
        .catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Error fetching attention required bills'
          this.initialLoader = false
          this.refreshLoader = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.attention-required-list {
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
