import { render, staticRenderFns } from "./AppChip.vue?vue&type=template&id=04c61777&scoped=true&"
import script from "./AppChip.vue?vue&type=script&lang=js&"
export * from "./AppChip.vue?vue&type=script&lang=js&"
import style0 from "./AppChip.vue?vue&type=style&index=0&id=04c61777&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c61777",
  null
  
)

export default component.exports