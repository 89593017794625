<template>
  <b-modal
    v-model="modalShow"
    size="sm"
    centered
    title="Download Rack Group Report"
    @hidden="$emit('modal-closed')"
  >
    <div>
      <b-form @submit.prevent="submitHandler">
        <b-form-group
          label="User"
        >
          <custom-v-select
            v-model="selectedUserId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="operationUsers"
            label="name"
            placeholder="Select User"
            :reduce="(item) => item.id"
          />
        </b-form-group>
      </b-form>
    </div>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="!selectedUserId || isDownloading"
        @click="submitHandler"
      >
        Download
        <b-spinner
          v-if="isDownloading"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import axios from 'axios'

import downloadFileMixin from '@/mixins/downloadFileMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BButton,
    BSpinner,
    BFormGroup,
    BForm,
    CustomVSelect,
  },
  mixins: [downloadFileMixin],
  props: {
    operationUsers: {
      type: Array,
      required: true,
    },
    rgIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      submitting: false,
      selectedUserId: null,
      isDownloading: false,
    }
  },
  methods: {
    submitHandler() {
      this.isDownloading = true
      const payload = { ids: this.rgIds, operation_user_id: this.selectedUserId }
      axios.post('/operations/download_rack_group_report', payload, {
        responseType: 'blob',
      }).then(res => {
        const { data } = res
        this.downloadXLSX(data, 'RackGroupReport')
        this.$emit('downloaded')
        this.modalShow = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.detail || 'Failed to download!',
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
        this.isDownloading = false
      })
    },
  },
}
</script>
