import { render, staticRenderFns } from "./AttentionRequiredBills.vue?vue&type=template&id=0d5ff87b&scoped=true&"
import script from "./AttentionRequiredBills.vue?vue&type=script&lang=js&"
export * from "./AttentionRequiredBills.vue?vue&type=script&lang=js&"
import style0 from "./AttentionRequiredBills.vue?vue&type=style&index=0&id=0d5ff87b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5ff87b",
  null
  
)

export default component.exports